<script>
	import Section from '$lib/Components/Layout/Section/Section.svelte';
</script>

<Section
	backgroundColor={'white'}
	class={['md:py-32', $$props.class ?? ''].join(' ')}
>
	<div>
		<slot />
	</div>
</Section>

<style>
	div :global(p) {
		color: var(--indow-blue);
		font-weight: 300;
		font-size: clamp(2rem, 5vw, 3rem);
		line-height: 1.25;
		max-width: 30ch;
	}
</style>
